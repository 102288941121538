* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.annoucement {
  background-color: #f8f8f8;
  padding: 10px;
  width: 100%;
  margin: 40px 0;
  padding: 10px 50px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.annoucement h1 {
  margin-bottom: 65px;
  color: rgb(183, 242, 64);
}
.annoucement h2 {
  color: #4d4d4d;
  margin-left: 10px;
  line-height: 60px;
}
.sideImage img {
  height: 350px;
  aspect-ratio: 4/3;
  position: relative;
  top: 100px;
  right: 50px;
  border-radius: 150rem;
  margin: 10px;
}
.services {
  margin-top: 150px;
}
.services > h2 {
  text-align: center;
}
.servicesListing {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 50px 20px;
}

.service {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 30%;
  background-color: #f8f8f8;
  padding: 30px;
  border-radius: 15px;
}

.service img {
  object-fit: contain;
  height: 200px;
  margin: 5px 15px;
  border-radius: 15px;
}

@media screen and (max-width: 768px) {
  .servicesListing {
    flex-direction: column;
  }
  .service {
    width: 80%;
    margin: 20px auto;
  }
  .annoucement {
    flex-direction: column;
    align-items: center;
  }
  .sideImage img {
    height: 250px;
  }
}
