#newReservationForm {
  width: 80%;
  margin: 15px auto;
}
.error {
  color: red !important;
}
.title {
  margin: 5px 25px;
}
