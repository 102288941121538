#isShownMenu {
  display: none;
}
.menu {
  display: inline;
}

.navbar {
  background-color: #f8f8f8;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: end;
  width: fit-content;
  display: inline;
}

.navbar li {
  margin: 0 10px;
  display: inline;
  cursor: pointer;
}

.navbar li a:hover {
  color: #abcdaf;
}

.logo {
  display: inline;
  cursor: pointer;
}

.logo img {
  margin-left: 10px;
  height: 50px;
}

.navbar a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.menu-toggle {
  font-size: 1.5rem;
  cursor: pointer;
  border: none;
  width: 40px;
  color: #333;
  background-color: transparent;
  display: none;
}

.menu-toggle:active span {
  transform: scale(0.9);
  box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.4);
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .navbar ul {
    flex-direction: column;
  }

  .navbar li {
    margin: 10px 0;
    text-align: center;
  }
  .open {
    display: block;
  }
  .hidden {
    display: none;
  }
  .menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;
  }
  .navbar {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .navbar ul {
    display: none;
  }
  .navbar li {
    display: block;
    text-align: center;
  }
  .menu-toggle {
    display: inline;
    font-size: large;
    text-align: center;
  }
  #isShownMenu:checked ~ .menu ul {
    display: block;
  }
}
